import Artist from "@/models/Artist";
import Venue from "@/models/Venue";
import Menu from "@/models/Menu";
import Track from '@/models/Track';
import Video from '@/models/Video';
import Gig from '@/models/Gig';

export default class Statistics {
    constructor({user, role}) {
        this._favArtists = [];
        this._favVenues = [];
        this._user = user;
        this._role = role;
        this._artist = {};
        this._venue = {};
        this._gigs = [];
        this.cards = {
            profileViews: {text: 'Total Profile Views: '},
            tracks: {text: 'Total Tracks: '},
            videos: {text: 'Total Videos: '},
            menus: {text: 'Total Menus: '},
            gigs: {text: 'Total Gigs: '},
            favorites: {text: 'Recently added:<br>'},
        }
    }

    async init() {
        switch (this._role) {
            case 'artist':
                this._artist = await Artist.where('user_id', this._user.id).include('artist_profile_views_count').first()
                await this.countTracks()
                await this.countVideos()
                break;
            case 'venue':
                this._venue = await Venue.where('user_id', this._user.id).include([
                    'venue_profile_views_count',
                    'venue_website_views_count',
                    'venue_call_to_action_clicks_count',
                ]).first()
                await this.countMenus()
                break;
        }
        await this.countViews()
        await this.countGigs()
        await this.loadFavorites()
    }

    async countViews() {
        switch (this._role) {
            case 'artist':
                this.cards.profileViews.text += this._artist.artist_profile_views_count
                break;
            case 'venue':
                this.cards.profileViews.text = `Profile views: ${this._venue.venue_profile_views_count}<br>`
                this.cards.profileViews.text += `Website views: ${this._venue.venue_website_views_count}<br>`
                this.cards.profileViews.text += `CTA clicks: ${this._venue.venue_call_to_action_clicks_count}`
                break;
        }
    }

    async countTracks() {
        const tracks = await Track.include('artist').get()
        this.cards.tracks.text += tracks.length
    }

    async countVideos() {
        const videos = await Video.include('artist').get()
        this.cards.videos.text += videos.length
    }

    async countMenus() {
        const menus = await Menu
            .include('venue')
            .where('user', this._user.id)
            .get()
        this.cards.menus.text += menus.length
    }

    async countGigs() {
        switch (this._role) {
            case 'artist':
                this._gigs = await Gig.where('artist_id', this._artist.id).get()
                break;
            case 'venue':
                if (this._venue.id) {
                    this._gigs = await Gig.where('venue_id', this._venue.id).get()
                }
                break;
        }
        this.cards.gigs.text += this._gigs.length
    }

    async loadFavArtists() {
        const ids = this._user['favoriteArtists'];

        if (!ids.length) {
            return
        }

        this._favArtists = await Artist.custom('artists/public').whereIn('id', ids).limit(2).get();
    }

    async loadFavVenues() {
        const ids = this._user['favoriteVenues'];

        if (!ids.length) {
            return
        }

        this._favVenues = await Venue.custom('venues/public').whereIn('id', ids).limit(2).get();
        this._favVenues = this._favVenues.data
    }

    async loadFavorites() {
        await this.loadFavArtists()
        await this.loadFavVenues()

        let favoritesList = '<ul class="mb-0">'
        this._favArtists.forEach(item => {
            favoritesList += `<li><a href="/artists/${item.slug}">${item.name}</a></li>`
        })
        this._favVenues.forEach(item => {
            favoritesList += `<li><a href="/venues/${item.slug}">${item.name}</a></li>`
        })
        favoritesList += '</ul>'
        this.cards.favorites.text = `Recently added:<br>${favoritesList}`
    }
}
