<template>
    <dashboard-layout>
        <v-row>
            <v-col cols="12" md="7" lg="5">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    Profile Views
                                </v-card-title>
                                <chart-view
                                    v-if="chartLoaded"
                                    :data="chartsData.profileViews"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" md="5" lg="7">
                <v-container fluid>
                    <v-row>
                        <v-col
                            v-for="card in Object.values(cards)" :key="card.title"
                            cols="12" lg="6" xl="4">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    <v-icon small color="primary" class="mr-2">{{ card.icon }}</v-icon>
                                    {{ card.title }}
                                </v-card-title>
                                <v-card-text
                                    v-if="card.text && !cardsLoading"
                                    v-html="card.text"
                                    >
                                </v-card-text>
                                <v-card-actions v-if="card.to">
                                    <v-btn
                                        :to="card.to"
                                        small
                                        text
                                        color="accent"
                                    >
                                        {{ card.toLabel }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import ChartView from "../../components/ChartView";
import cards from "./cards";
import {chartsData} from '@/plugins/charts-data'
import VenueProfileView from "@/models/VenueProfileView";
import DashboardLayout from "@/layouts/DashboardLayout";
import Statistics from "../dashboard/Statistics";


export default {
    name: "dashboard-index",
    components: {DashboardLayout, ChartView},
    data: function () {
        return {
            cards,
            chartLoaded: false,
            cardsLoading: true,
            chartsData: {
                profileViews: null,
            },
        }
    },
    mounted() {
        this.init()
        this.loadChart()
    },
    methods: {
        async init() {
            this.cardsLoading = true
            const statistics = new Statistics({user: this.$auth.user(), role: 'venue'})
            await statistics.init()
            for (let key in this.cards) {
                Object.assign(this.cards[key], statistics.cards[key])
            }
            this.cardsLoading = false
        },
        async loadChart() {
            this.chartLoaded = false
            const data = await VenueProfileView.getNewCounts();
            this.chartsData.profileViews = chartsData(data, '#ce790a');
            this.chartLoaded = true
        }
    }
}
</script>

<style scoped>

</style>
