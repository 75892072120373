import Model from './Model'
import newCounts from "@/models/helpers/new-counts";

export default class VenueProfileView extends Model {
    // Set the resource route of the model
    resource() {
        return 'analytics/venue-profile-views'
    }

    static getNewCounts = async function (days = 10, format = 'Y-m-d') {
        return newCounts(`/api/analytics/venue-profile-views/new-counts`, days, format)
    }
}
