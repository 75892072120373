export default {
    profileViews: {
        icon: 'mdi-eye',
        title: 'Profile Views',
        text: 'Profile Views:'
    },
    favorites: {
        to: {name: 'dashboard.favourites'},
        toLabel: 'View all',
        icon: 'mdi-heart',
        title: 'Favorites',
        text: 'Recently added: • first • second'
    },
    menus: {
        to: {name: 'venue.menus.index'},
        toLabel: 'View all',
        icon: 'mdi-video',
        title: 'Menus',
        text: 'Total menus: 18'
    },
    gigs: {
        to: {name: 'venue.gigs.index'},
        toLabel: 'View all',
        icon: 'mdi-calendar',
        title: 'Gigs',
        text: 'Total Gigs: 27'
    },
}
